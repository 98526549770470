<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" class="ds-ts-row")
    td.ds-ts-id.pl-1(@click="linkToApplication")
      span.pr-1 {{ item.id }}
      ico-arrow-link
    td.ds-ts-datetime {{ item.created_at | convertToDefaultDateHourFormat }}
    td.ds-ts-manager {{ item.created_by || '-' }}
    td.ds-ts-school {{ item.driving_school.name || '-' }}
    td.ds-ts-count {{ item.count || '-' }}
    td.ds-ts-amount €{{ item.amount || '-' }}
    td.ds-ts-calculated €{{ item.calculated || '-' }}
    td.ds-ts-comment
      v-tooltip(top max-width="600")
        template(v-slot:activator="{on}")
          div.ds-ts-comment-text(v-on="on") {{ item.comment || '-' }}
        span {{ item.comment || '-' }}
</template>

<script>
import { convertToDefaultDateHourFormat } from '@/util'

export default {
  props: {
    isSelected: Boolean,
    select: Function,
    item: {type: Object, required: true},
    index: Number,
    pagination: Object,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    loading: Boolean
  },

  filters: {
    convertToDefaultDateHourFormat
  },

  methods: {
    linkToApplication() {
      this.$emit('click:link', this.item)
      this.$scrollTo('html')
    }
  },

  components: {
    icoArrowLink: () => import('@/assets/img/ui/crm/IcoArrowLink.vue')
  }
}
</script>

<style lang="scss" scoped>
.contents:nth-child(2n) tr {
  background: #F4F7F9;
}

.ds-ts-row {
  .ds-ts- {
    &id {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all .25s ease-in-out;

      &:hover {
        svg {
          transform: rotate(45deg) scale(1.2);
          transition: all .25s ease-in-out;
        }
      }
    }
    &comment {
      max-width: 250px;

      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &datetime {
      min-width: 140px;
    }
  }
}
</style>

<style lang="scss">
.ds-ts-id {
  padding-left: 4px !important;
}
</style>
